
import { MountOrder, OrderShowOneRequest } from "@/services/api/ApiOrder";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { IOrderShow } from "@/services/models/order";
import { IBoxSimplified } from "@/services/models/box";
import QrCode from "@/components/qrcode.vue";
import {
  notificationError,
  notificationSuccess
} from "@/utils/NotificationUtils";
@Component({ components: { QrCode } })
export default class OrderDatail extends Vue {
  @Prop() open_modal!: boolean;
  @Prop() order_id!: number;
  loading = false;
  order: IOrderShow = {} as IOrderShow;
  mountBox = false;
  boxes: IBoxSimplified[] = [];
  cameraModal = false;
  orderStatus = 0;

  fields: { key: string; label: string }[] = [
    {
      key: "id",
      label: "Caixa"
    },
    {
      key: "checked",
      label: "Verificada"
    },
    {
      key: "actions",
      label: "Ações"
    }
  ];

  @Watch("order_id")
  async openModalWatch(): Promise<void> {
    this.getOrder();
  }

  async getOrder(): Promise<void> {
    this.loading = true;
    const resp = await OrderShowOneRequest(this.order_id);
    this.order = resp;
    this.boxes = resp.boxes.map((box) => {
      return { ...box, checked: false };
    });
    this.orderStatus =
      resp.traking_status[resp.traking_status.length - 1].order_status_id;

    this.loading = false;
  }
  close(): void {
    this.$emit("closeModal");
    this.cameraModal = false;
    this.mountBox = false;
  }

  openMountBox(): void {
    this.mountBox = true;
  }

  cancelMountBox(): void {
    this.mountBox = false;
  }

  openReader(): void {
    this.cameraModal = true;
  }

  closeReader(): void {
    this.cameraModal = false;
  }

  readCode(code: string): void {
    const newBoxes = this.boxes.slice();
    const box = newBoxes.find((box) => box.code == code);
    if (box) {
      box.checked = true;
      this.boxes = newBoxes;
    } else {
      notificationError("Você leu o código da caixa errada.");
    }
  }

  async finishMount(): Promise<void> {
    try {
      const notAllChecked = this.boxes.find((box) => box.checked == false);
      if (notAllChecked) {
        notificationError("Você precisa ler o codigo de todas as caixas.");
      } else {
        await MountOrder(this.order.id);
        notificationSuccess("Pedido montado com sucesso.");
        this.close();
      }
    } catch (error) {
      notificationError(
        "Algo de inesperado aconteceu, tente novamente em alguns minutos."
      );
    }
  }
}
