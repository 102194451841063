
import { OrderListRequest } from "@/services/api/ApiOrder";
import { IOrderList } from "@/services/models/order";
import { Vue, Component, Watch } from "vue-property-decorator";
import OrderDetail from "./order-datail/OrderDetail.vue";
@Component({ components: { OrderDetail } })
export default class Orders extends Vue {
  orders: IOrderList[] = [];
  currentPage = 1;
  perPage = 5;
  totalRows = 1;
  fields: { key: string; label: string }[] = [
    {
      key: "id",
      label: "Nº"
    },
    {
      key: "origin_regional",
      label: "Origem"
    },
    {
      key: "destination_regional",
      label: "Destino"
    },
    {
      key: "order_status",
      label: "Status"
    },
    {
      key: "actions",
      label: "Ações"
    }
  ];
  order_id = 0;
  open_detail = false;
  @Watch("perPage")
  async onPerPageChange() {
    await this.listOrders();
  }
  @Watch("currentPage")
  async onCurrentPageChange() {
    await this.listOrders();
  }
  async mounted(): Promise<void> {
    this.listOrders();
  }

  async listOrders(): Promise<void> {
    const resp = await OrderListRequest(this.perPage, this.currentPage);
    this.orders = resp.data;
    this.totalRows = resp.total;
  }

  showDetail(id: number) {
    this.order_id = id;
    this.open_detail = true;
  }

  closeModal() {
    this.open_detail = false;
    this.listOrders();
  }
}
